var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('v-layout',{attrs:{"px-2":"","py-2":"","mt-1":"","row":"","wrap":""}},[_c('v-flex',{attrs:{"sm6":"","px-2":""}},[_c('a-form-view',{attrs:{"value":_vm.data,"model":_vm.getFormModel(['room_type', 'name', 'description']),"config":{ dense: true }}})],1),_c('v-flex',{attrs:{"sm6":"","px-2":""}},[_c('a-form-view',{attrs:{"value":_vm.data,"model":[
        ..._vm.getFormModel(['date_buy', 'vendor', 'article']),
        {
          name: 'amount',
          title: 'Кол-во',
          value: _vm.data.amount + ' ' + _vm.data.measure,
          type: 'string',
        },
        {
          name: 'price_info',
          value: _vm.price_info,
          title: 'Стоимость',
          type: 'string',
        },
      ],"config":{ dense: true }}})],1),_c('v-flex',{attrs:{"sm12":""}},[_c('a-form-view',{attrs:{"value":_vm.data,"model":_vm.getFormModel(['link']),"config":{ dense: true }},on:{"click":function($event){return _vm.onClick($event)}}})],1),_c('v-flex',{attrs:{"sm12":""}},[_c('a-view-images2',{attrs:{"value":_vm.data.photo,"size":200}})],1),_c('popup-menu',{attrs:{"items":_vm.popupMenuList,"position":_vm.popupMenuPosition},on:{"click":function($event){return _vm.onClickMenu($event)}},model:{value:(_vm.popumMenuShow),callback:function ($$v) {_vm.popumMenuShow=$$v},expression:"popumMenuShow"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }